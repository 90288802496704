export default class MonitorVMD {
    constructor(options = {}) {
        Object.assign(this, {
            order: 0,
            files: [],
            filesPlayTime: 0,
            imagePlayTime: 5,
            creationDateTime: '',
            lastModifiedDateTime: '',
        }, options);
        Object.assign(this, options);
    }
}