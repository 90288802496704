<template>
  <div id="contents" class="contents monitor page">
    <div class="content_head">
      <!-- s: 페이지 타이틀 -->
      <div class="page_ttl_wrap has_btn">
        <h2 class="page_ttl">모니터 VMD</h2>
        <div class="btn_group">
          <span>최근 발행일시 {{recentPublishTime ? moment(recentPublishTime).format('YYYY.MM.DD HH:mm:ss') : '없음'}}</span>
          <!-- Dev: 변경사항이 없을시 비활성화 -->
          <button type="button" class="btn strong2 w_m h_l" @click="publish">발행하기</button>
        </div>
      </div>
    </div>
    <!-- e: 페이지 타이틀 -->
    <!-- s: content_body -->
    <div class="content_body">
      <!-- s: item_list_sec-->
      <div class="item_list_sec">
        <!-- Dev: 상품 추가 wrap -->
        <div class="item_list_wrap none min" :class="{is_active : activeIndex === -1}"
             @click="clickAddBtn()">
          <div class="item_list_head min">
            <div class="item_thumb">
              <button>추가버튼</button>
            </div>
          </div>
        </div>
        <!-- Dev : 등록된 상품  -->
        <div class="registration_item_wrap" v-if="orderedVmd">
          <Container class="registration_item_area"
                     orientation="horizontal"
                     tag="div"
                     :drop-placeholder="dropPlaceholderOptions"
                     @drop="onDrop">
            <Draggable class="item_list_wrap min" v-for="(item, index) in orderedVmd" :key="'item' + index"
                       :class="{is_active : index === activeIndex}" @click="clickVmd(index, item)">
              <div class="item_list_head min">
                <div class="delete_btn_area">
                  <button class="delete_btn" @click="removeList(item)">삭제버튼</button>
                </div>
                <div class="item_list_body" v-if="item.files && item.files.length > 0">
                  {{ item.name ? item.name : item.files && item.files[0] ? fileNameFromUrl(item.files[0]) :
                  item.files[0] }}
                </div>
              </div>
            </Draggable>
          </Container>
        </div>
      </div>
      <!-- e: item_list_sec-->

      <!-- s: preview_sec -->
      <div class="preview_sec">
        <div class="preview_slide_time_area">
          <!-- Dev : 시간 미입력시 is_error 클래스 추가          -->
          <div class="input_area inline"
               :class="!isNaN(vmdPlayTime[activeIndex]) && vmdPlayTime[activeIndex] && vmdPlayTime[activeIndex] > 0 ? '' : 'is_error'">
            <label>이미지 슬라이드 시간</label>
            <div class="input_box">
              <!-- Dev : default 값 5초 -->
              <input type="number" :disabled="!vmdEditMode[activeIndex]"
                     v-model="vmdPlayTime[activeIndex]"
                     @change="countPlayTime" @input="countPlayTime">
            </div>
            <div class="input_txt">초</div>
            <!-- Dev : 이미지가 총 재생되는 재생시간 -->
            <div class="play_time">(총 재생 시간 :
              {{ totalPlayTime[activeIndex] ? totalPlayTime[activeIndex].toFixed(2) : '0' }}초)
            </div>
            <!-- Dev: 에러시 출력 -->
            <small class="error_txt"
                   v-if="isNaN(vmdPlayTime[activeIndex]) || !vmdPlayTime[activeIndex] || vmdPlayTime[activeIndex] < 1">슬라이드
              시간을
              입력 해 주세요.</small>
          </div>
        </div>
        <!-- Dev : 만능체험대 일시 preview_wrap -->
        <div class="preview_wrap experience">
          <div class="upload_guide" role="button">
            <div class="tooltip_area">
              <img src="../../../assets/img/common/tooltip.png" alt="">
              <p>파일 업로드 가이드</p>
            </div>
            <div class="layer_info">
              <div class="info_area" v-for="(item, i) in tooltip" :key="'item' + i">
                <div class="ttl">{{ item.ttl }}</div>
                <div class="cnt">
                  <p>{{ item.file }}</p>
                  <p>{{ item.memory }}</p>
                  <p>{{ item.experience }}</p>
                </div>
              </div>
              <p class="info_txt">※ 파일명은 영어로 등록해주세요.</p>
            </div>
          </div>
          <div class="experience_wrap">
            <div class="preview_area edit">
              <div class="swiper_nav_prev"></div>
              <div class="swiper_nav_next"></div>
              <div class="slide_area">
                <div class="swiper_pagination"></div>
                <!-- Dev : 미리보기 모드 일시 -->
                <template v-if="vmdEditMode[activeIndex] === false">
                  <swiper @swiper="setControlledSwiper"
                          slidesPerView="auto"
                          :loop="false"
                          :navigation=" { nextEl: '.swiper_nav_next', prevEl: '.swiper_nav_prev' }"
                  >
                    <swiper-slide v-if="!clickedVmd.files || clickedVmd.files.length === 0">
                      <!-- Dev : 등록된 파일이 없는 경우 -->
                      <template v-if="true">
                        <div class="upload_btn_wrap">
                          <div class="upload_btn" role="button">
                            <div class="txt_area">
                              <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                            </div>
                            <div class="btn_area">
                              <button type="button" class="btn basic w_smm h_m">파일 추가
                              </button>
                              <input type="file"
                                     accept="image/gif,image/jpeg,image/png,video/mp4"
                                     @change="onFileChange($event)">
                            </div>
                            <div class="info_area">
                              <p>
                                png/jpg, 1920*1080<br>
                                mp4, 1920*1080
                              </p>
                            </div>
                          </div>
                        </div>
                      </template>
                    </swiper-slide>

                    <swiper-slide v-else v-for="(file, index) in clickedVmd.files" v-if="clickedVmd.files && clickedVmd.files.length > 0">
                      <!--Dev: 파일이 이미지일 경우-->
                      <template v-if="isImage(file)">
                        <div class="model_img_wrap">
                          <div class="model_img_box"
                               :style="`background-image: url('${getFile(file)}')`">
                          </div>
                          <div class="hover_btn_area" v-if="vmdEditMode[activeIndex]">
                            <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                            <button class="modify_btn">
                              <img src="../../../assets/img/common/video_modify.png"
                                   alt="">
                              <input type="file" accept=".mp4"
                                     @change="onFileChange($event, index)">
                            </button>
                            <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                            <button class="delete_btn" @click="deleteImage(index)">삭제
                            </button>
                          </div>
                        </div>
                      </template>
                      <!--Dev: 파일이 영상일 경우-->
                      <template v-if="isVideo(file)">
                        <div class="upload_video">
                          <video :ref="`video-${file}`" class="video_box">
                            <source :src="getFile(file)">
                          </video>
                          <button class="video_play_btn play"
                                  @click="playVideo('play', file, index)"
                                  v-if="playStatus[index] === false || !playStatus[index]">
                            <img src="../../../assets/img/common/video_play_btn.png" alt="">
                          </button>
                          <button class="video_play_btn pause"
                                  @click="playVideo('pause', file, index)"
                                  v-if="playStatus[index] === true">
                            <img src="../../../assets/img/common/video_pause_btn.png"
                                 alt="">
                          </button>
                          <div class="hover_btn_area" v-if="vmdEditMode[activeIndex]">
                            <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                            <button class="modify_btn">
                              <img src="../../../assets/img/common/video_modify.png"
                                   alt="">
                              <input type="file" accept=".mp4"
                                     @change="onFileChange($event, index)">
                            </button>
                            <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                            <button class="delete_btn" @click="deleteImage(index)">삭제
                            </button>
                          </div>
                        </div>
                      </template>
                    </swiper-slide>
                  </swiper>
                </template>
                <template v-else>
                  <swiper @swiper="setControlledSwiper"
                          slidesPerView="1.2"
                          :loop="false"
                          :navigation=" { nextEl: '.swiper_nav_next', prevEl: '.swiper_nav_prev' }"
                  >
                    <swiper-slide>
                      <!-- Dev : 등록된 파일이 없는 경우 -->
                      <template v-if="true">
                        <div class="upload_btn_wrap">
                          <div class="upload_btn" role="button">
                            <div class="txt_area">
                              <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                            </div>
                            <div class="btn_area">
                              <button type="button" class="btn basic w_smm h_m">파일 추가
                              </button>
                              <input type="file"
                                     accept="image/gif,image/jpeg,image/png,video/mp4"
                                     @change="onFileChange($event)">
                            </div>
                            <div class="info_area">
                              <p>
                                png/jpg, 1920*1080<br>
                                mp4, 1920*1080
                              </p>
                            </div>
                          </div>
                        </div>
                      </template>
                    </swiper-slide>
                    <swiper-slide v-for="(file, index) in clickedVmd.files" v-if="clickedVmd.files && clickedVmd.files.length > 0">
                      <!--Dev: 파일이 이미지일 경우-->
                      <template v-if="isImage(file)">
                        <div class="model_img_wrap">
                          <div class="model_img_box"
                               :style="`background-image: url('${getFile(file)}')`">
                          </div>
                          <div class="hover_btn_area">
                            <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                            <button class="modify_btn">
                              <img src="../../../assets/img/common/video_modify.png"
                                   alt="">
                              <input type="file" accept=".mp4"
                                     @change="onFileChange($event, index)">
                            </button>
                            <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                            <button class="delete_btn" @click="deleteImage(index)">삭제
                            </button>
                          </div>
                        </div>
                      </template>
                      <!--Dev: 파일이 영상일 경우-->
                      <template v-if="isVideo(file)">
                        <div class="upload_video">
                          <video :ref="`video-${file}`" class="video_box">
                            <source :src="getFile(file)">
                          </video>
                          <button class="video_play_btn play"
                                  @click="playVideo('play', file, index)"
                                  v-if="playStatus[index] === false || !playStatus[index]">
                            <img src="../../../assets/img/common/video_play_btn.png" alt="">
                          </button>
                          <button class="video_play_btn pause"
                                  @click="playVideo('pause', file, index)"
                                  v-if="playStatus[index] === true">
                            <img src="../../../assets/img/common/video_pause_btn.png"
                                 alt="">
                          </button>
                          <div class="hover_btn_area">
                            <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                            <button class="modify_btn">
                              <img src="../../../assets/img/common/video_modify.png"
                                   alt="">
                              <input type="file" accept=".mp4"
                                     @change="onFileChange($event, index)">
                            </button>
                            <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                            <button class="delete_btn" @click="deleteImage(index)">삭제
                            </button>
                          </div>
                        </div>
                      </template>
                    </swiper-slide>
                  </swiper>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e: preview_sec -->
    </div>
    <!-- e : content_body    -->


    <!-- s: content_foot -->
    <div class="content_foot">
      <div class="btn_group center">
        <!-- Dev : 첨부파일에 대한 부분만 리셋        -->
        <button class="btn basic w_m h_l" v-if="vmdEditMode[activeIndex] && activeIndex === -1"
                @click="clickVmd(0, vmds[0])">취소
        </button>
        <button class="btn basic w_m h_l" v-if="vmdEditMode[activeIndex] && activeIndex !== -1"
                @click="vmdEditMode[activeIndex] = false">취소
        </button>
        <!-- Dev : 클릭 시 메인 영상 등록: 파일 첨부 반영  -->
        <button v-if="!vmdEditMode[activeIndex]" class="btn basic w_m h_l"
                @click="vmdEditMode[activeIndex] = true">수정
        </button>
        <!--                <button v-else @click="clickAddBtn" class="btn basic w_m h_l">취소</button>-->
        <button v-else class="btn strong w_m h_l" @click="registVmd()">저장</button>
      </div>
    </div>
    <!-- e : content_foot  -->
    <Dialog :isOpen="deleteDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="deleteDialogState = false"
            @yesDialog="deleteVmd()">
      <template #body>
        <h3>삭제 확인</h3>
        <p>해당 VMD를 삭제하시겠습니까?<br>
          변경된 내용은 채널에서 “발행하기”를<br>
          진행해야 Tablet에 반영됩니다.
        </p>
      </template>
    </Dialog>

  </div>
</template>

<script>
import {Container, Draggable} from "vue3-smooth-dnd";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper.min.css';
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper/core';
import moment from "moment";
import getBlobDuration from "get-blob-duration";
import Dialog from "@/components/dialog";
import {applyDrag} from "@/plugins/dnd-helpers";
import MonitorVMD from "@/plugins/firebase/model/monitorvmd";
import HotDeal from "@/plugins/firebase/model/vmd/hotdeal";
import HalfPrice from "@/plugins/firebase/model/vmd/halfprice";
import Explore from "@/plugins/firebase/model/vmd/explore";
import VMD from "@/plugins/firebase/model/vmd";
import {fileNameFromUrl} from "@/plugins/network";

SwiperCore.use([Autoplay, Navigation, Pagination]);

export default {
  name: "index",
  layout: 'new-admin',
  components: {
    Container: Container,
    Draggable: Draggable,
    Swiper,
    SwiperSlide,
    Dialog,
  },
  computed: {
    orderedVmd() {
      return this.vmds?.sort((a, b) => {
        return a.order - b.order;
      });
    },
    getDesc() {
      if (this.clickedVmd && this.clickedVmd.desc) {
        return this.clickedVmd.desc.filter((desc) => {
          if (desc && desc.length > 0) return true;
        });
      }
      return [];
    }
  },
  data() {
    return {
      moment: moment,
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      tooltip: [
        {
          ttl: '이미지 파일형식',
          file: 'JPG, PNG',
          memory: '용량 2MB 이하',
          display: '해상도 1000*1000',
          experience: '해상도 1920*1080',
        },
        {
          ttl: '영상 파일형식',
          file: 'MP4',
          memory: '용량 30MB 이하',
          display: '해상도 1920*1080',
          experience: '해상도 1920*1080',
        },
      ],
      deleteDialogState: false,
      controlledSwiper: null,
      activeIndex: -1,
      vmdEditMode: [],
      vmdPlayTime: [],
      totalPlayTime: [],
      playStatus: [],

      monitor_vmd_publish_key: 'publish/monitorvmd',
      monitor_vmd_root_key: 'monitorvmd',
      recentPublishTime: '',
      vmds: null,
      originalVmds: {},
      clickedVmd: new MonitorVMD(),
      deleteVmdHash: null,
      canLeaveSite: true,
      isUploading: false,
    }
  },
  methods: {
    fileNameFromUrl: fileNameFromUrl,
    async publish() {
      const time = this.timeToString(moment());
      await this.$firebase.database().ref(this.monitor_vmd_publish_key).child('lastPublishDateTime').set(time);
      this.toastSuccess('발행 완료.');
    },
    onFileChange(e, index) {
      if (typeof index === 'undefined' && this.clickedVmd.files.length >= 3) {
        this.toastDanger('VMD당 이미지는 최대 3개까지 입니다.');
        return;
      }
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      let file = files[0];

      const isImage = this.isImage(file);
      const isVideo = this.isVideo(file);
      const maxImageSize = 2 * 1024 * 1024;
      const maxVideoSize = 80 * 1024 * 1024;
      if (!isImage && !isVideo) {
        this.toastDanger('이미지는 .jpg, .png, 동영상은 .mp4만 지원합니다.');
        return;
      }
      if (isImage) {
        if (maxImageSize < file.size) {
          this.toastDanger('이미지 용량은 2MB 미만으로 등록해주세요.');
          return;
        }
      } else if (isVideo) {
        if (maxVideoSize < file.size) {
          this.toastDanger('동영상 용량은 80MB 미만으로 등록해주세요.');
          return;
        }
      }
      if (isNaN(Number(index))) {
        this.clickedVmd.files.push(file);
      } else {
        if (this.isVideo(file)) {
          let video = this.$refs[`video-${file}`];
          if (!video) video = this.$refs[`video-${this.clickedVmd.files[index]}`];
          if (video) {
            const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
            if (isVideoPlaying) {
              try {
                video.pause();
              } catch (e) {
              }
            }
            video.load();
          } else {

          }
          this.playStatus[index] = false;
        }
        this.clickedVmd.files[index] = file;
      }
      this.countPlayTime();
      //this.countPlayTime();
    },
    deleteImage(index) {
      this.clickedVmd.files.splice(index, 1);
      this.$nextTick(() => {
        this.clickedVmd.files.forEach((file) => {
          if (this.isVideo(file)) {
            let video = this.$refs[`video-${file}`];
            if (!video) video = this.$refs[`video-${this.clickedVmd.files[index]}`];
            if (video) {
              const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
              if (isVideoPlaying) {
                try {
                  video.pause();
                } catch (e) {
                }
              }
              video.load();
            } else {

            }
            this.playStatus[index] = false;
          }
        });
      });
    },
    setControlledSwiper(swiper) {
      this.controlledSwiper = swiper;
    },
    playVideo(status, file, index) {
      const video = this.$refs[`video-${file}`];
      if (status === 'play') {
        video.play();
        this.playStatus[index] = true;
      } else {
        video.pause();
        this.playStatus[index] = false;
      }
    },
    anyFileExists() {
      return this.clickedVmd.files.map((file) => {
        return this.getFile(file) != null;
      }).some((check) => check === true);
    },
    clickAddBtn() {
      this.activeIndex = -1;
      this.clickedVmd = new MonitorVMD();
      this.vmdEditMode[-1] = true;
    },
    onDrop(dropResult) {
      if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
        this.vmds[dropResult.removedIndex].order = dropResult.addedIndex + 1;
        this.vmds[dropResult.addedIndex].order = dropResult.removedIndex + 1;
      }
      this.vmds = applyDrag(this.vmds, dropResult);
      this.vmds.forEach((item, index) => {
        item.order = index + 1;
        var original = this.originalVmds.find((origin) => {
          return item.hash === origin.hash;
        });
        original.order = index + 1;
      });
      this.vmds = this.vmds.sort((a, b) => {
        return a.order - b.order;
      });
      this.originalVmds = this.originalVmds.sort((a, b) => {
        return a.order - b.order;
      });
      this.vmds.forEach((item) => {
        this.$firebase.database().ref(this.monitor_vmd_root_key).child(item.hash).set(item);
      });

      const click = this.vmds[dropResult.addedIndex];
      this.clickVmd(dropResult.addedIndex, click);
      this.canLeaveSite = false;
    },
    clickVmd(index, vmd) {
      if (this.vmds) {
        if (index && vmd) {
          const original = this.originalVmds?.find((item) => item.hash === vmd.hash);
          const find = this.vmds.findIndex((item) => item.hash === vmd.hash);
          if (find > -1) {
            this.vmds[find] = JSON.parse(JSON.stringify(original));
          }
        }
      }

      this.activeIndex = index;
      this.clickedVmd = new MonitorVMD(vmd);
      if (this.clickedVmd.files && this.clickedVmd.files.length > 0) {
        const videoBoxes = document.querySelectorAll('.video_box');
        videoBoxes.forEach((video) =>{
          this.playStatus[index] = false;
          video.pause();
          video.load();
        });
      }
      this.vmdEditMode[index] = false;
      this.vmdEditMode[-1] = false;
      this.countPlayTime();
    },
    countPlayTime() {
      this.totalPlayTime[this.activeIndex] = 0;
      if (this.clickedVmd && this.clickedVmd.files) {
        for (const file of this.clickedVmd.files) {
          const isVideo = this.isVideo(file);
          const isImage = this.isImage(file);
          if (isVideo) {
            try {
              const blob = URL.createObjectURL(file);
              getBlobDuration(blob).then((i) => {
                this.totalPlayTime[this.activeIndex] += i;
              });
            } catch (ignore) {
              fetch(file).then(res => res.blob()).then(blob => {
                getBlobDuration(blob).then((i) => {
                  this.totalPlayTime[this.activeIndex] += i;
                });
              });
            }
          } else if (isImage) {
            this.totalPlayTime[this.activeIndex] += Number(this.vmdPlayTime[this.activeIndex]);
          } else {
            if (file) {
              //console.log(file);
              const url = file.toString().toLowerCase();
              if (url.indexOf('.mp4')) {
                try {
                  const blob = URL.createObjectURL(file);
                  getBlobDuration(blob).then((i) => {
                    this.totalPlayTime[this.activeIndex] += i;
                  });
                } catch (ignore) {
                  fetch(file).then(res => res.blob()).then(blob => {
                    getBlobDuration(blob).then((i) => {
                      this.totalPlayTime[this.activeIndex] += i;
                    });
                  });
                }
              } else {
                this.totalPlayTime[this.activeIndex] += Number(this.vmdPlayTime[this.activeIndex]);
              }
            }
          }
        }
      }
    },
    async getFileDuration(file) {
      const isVideo = this.isVideo(file);
      const isImage = this.isImage(file);
      if (isVideo) {
        const blob = URL.createObjectURL(file);
        return await getBlobDuration(blob);
      } else if (isImage) {
        return new MonitorVMD().imagePlayTime;
      } else {
        if (file) {
          //console.log(file);
          const url = file.toString().toLowerCase();
          if (url.indexOf('.mp4')) {
            const blob = URL.createObjectURL(file);
            return await getBlobDuration(blob);
          } else {
            return new MonitorVMD().imagePlayTime;
          }
        }
      }
      return 0;
    },
    async upload(hash, image, current, total) {
      let storageRef = this.$firebase.storage().ref();
      let imageRef = storageRef.child(hash).child(image.name);
      let uploadTask = imageRef.put(image);
      return new Promise(((resolve, reject) => {
        uploadTask.on('state_changed', (snapshot) => {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          const gage = (Math.floor(100 / total) * current) + (Math.floor(progress) / total);
          this.$Progress.set(gage);
          //console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case this.$firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log('Upload is paused');
              break;
            case this.$firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log('Upload is running');
              break;
          }
        }, (error) => {
          console.error('[ERROR] state_changed', error);
          reject(error);
        }, () => {
          // 완료 다운로드 예시
          resolve(uploadTask.snapshot.ref.getDownloadURL());
        });
      }));
    },

    async deleteVmd() {
      // 2022-01-25 파일 삭제 임시 주석
      //await this.deleteFolderContents(this.deleteVmdHash);
      await this.$firebase.database().ref(this.monitor_vmd_root_key).child(this.deleteVmdHash).remove().then(() => {
        if (this.vmds && this.vmds.length > 0) {
          this.clickVmd(0, this.orderedVmd[0]);
        } else {
          this.clickAddBtn();
        }
        // 순서 재정렬
        this.vmds = this.vmds.filter((item) => item.hash !== this.deleteVmdHash);
        this.vmds.sort((a,b)=>a.order-b.order).forEach((item, index) => {
          item.order = index + 1;
          var original = this.originalVmds.find((origin) => {
            return item.hash === origin.hash;
          });
          original.order = index + 1;
        });
        this.vmds.forEach((item) => {
          this.$firebase.database().ref(this.monitor_vmd_root_key).child(item.hash).set(item);
        });

        this.deleteVmdHash = '';
        this.deleteDialogState = false;
        this.toastSuccess('VMD가 삭제 되었습니다.');

        this.$firebase.database().ref(this.monitor_vmd_root_key).once('value', (sn) => {
          let monitorvmd = sn.val() || [];

          let arr = [];
          for (const [hash, vmd] of Object.entries(monitorvmd)) {
            vmd.hash = hash;
            arr.push(vmd);
          }
          this.vmds = arr;

          this.reloadData();
          this.originalVmds = JSON.parse(JSON.stringify(this.vmds));
          if (this.vmds && this.vmds.length > 0) {
            this.clickedVmd = this.orderedVmd[0];
            this.activeIndex = 0;
            this.clickVmd(0, this.orderedVmd[0]);
          } else {
            this.clickAddBtn();
          }
          this.pageLoaded = true;
        })
      });
    },
    deleteFolderContents(path) {
      const ref = this.$firebase.storage().ref(path);
      return ref.listAll()
          .then(dir => {
            dir.items.forEach(fileRef => {
              this.deleteFile(ref.fullPath, fileRef.name);
            });
            dir.prefixes.forEach(folderRef => {
              this.deleteFolderContents(folderRef.fullPath);
            })
          })
          .catch(error => {
            console.log(error);
          });
    },
    deleteFile(pathToFile, fileName) {
      const ref = this.$firebase.storage().ref(pathToFile);
      const childRef = ref.child(fileName);
      childRef.delete();
      this.canLeaveSite = false;
    },
    removeList(item) {
      this.deleteVmdHash = item.hash;
      this.deleteDialogState = true;
    },
    async registVmd() {
      if (this.isUploading) {
        return;
      }
      if (!this.anyFileExists()) {
        this.toastDanger('최소 하나의 파일이 등록되어야 합니다');
        return;
      }
      //업로드 시작
      this.isUploading = true;
      this.loadingOverlay(async (loader) => {
        if (!this.clickedVmd.hash) {
          this.clickedVmd.hash = this.$firebase.database().ref(this.monitor_vmd_root_key).push(this.clickedVmd).key;
          this.vmds.push(this.clickedVmd);
        }else{
          let clickedIndex = this.vmds.findIndex((item) => item.hash === this.clickedVmd.hash);
          this.vmds[clickedIndex] = this.clickedVmd;
        }
        let newUploads = this.orderedVmd.filter((item) => item.order === 0);
        let newUploadSize = newUploads.length;
        for await (let [index, vmd] of this.vmds.entries()) {
          if (vmd.files && vmd.files.length > 0) {
            vmd.creationDateTime = this.timeToString(moment());
            vmd.lastModifiedDateTime = this.timeToString(moment());
            vmd.imagePlayTime = this.vmdPlayTime[index] ? this.vmdPlayTime[index] : this.vmdPlayTime[-1];
            if(vmd.order > 0){
              vmd.order = vmd.order + newUploadSize;
            }
            let availables = vmd.files.filter(x => x && typeof x !== 'undefined');
            if (availables.length > 0) {
              this.$Progress.start();
              for await (const file of availables) {
                if (file && file instanceof File) {
                  const index = availables.indexOf(file);
                  let downloadUrl = await this.upload(vmd.hash, file, index, availables.length);
                  vmd.files[index] = downloadUrl;
                  vmd.filesPlayTime += await this.getFileDuration(file);
                }
              }
              if (vmd.filesPlayTime) {
                vmd.filesPlayTime = Number(vmd.filesPlayTime.toFixed(2));
              }
              this.$Progress.finish();
              vmd.lastModifiedDateTime = this.timeToString(moment());
            }
          }
        }
        newUploads.forEach((item, index) => {
          item.order = index + 1;
        });
        for await (let [index, vmd] of this.vmds.entries()) {
          await this.$firebase.database().ref(this.monitor_vmd_root_key).child(vmd.hash).set(vmd);
        }
        this.toastSuccess('VMD가 등록되었습니다.');
        if (this.activeIndex === -1) {
          this.originalVmds = JSON.parse(JSON.stringify(this.vmds));
          this.reloadData();
          this.clickVmd(0, this.clickedVmd);
        }
        this.vmdEditMode[this.activeIndex] = false;
        this.isUploading = false;
        loader.hide();
      });
    },
    reloadData() {
      this.$firebase.database().ref(this.monitor_vmd_publish_key).once('value', (sn) => {
        const publish = sn.val() || {};
        if (publish) {
          this.recentPublishTime = publish.lastPublishDateTime;
        }
      });

      this.vmdPlayTime[-1] = 5;
      this.totalPlayTime[-1] = 0;
      if (this.vmds) {
        for (const [index, vmd] of this.vmds.entries()) {
          this.vmdPlayTime[index] = Number(vmd.imagePlayTime);
          this.totalPlayTime[index] = Number(0);
        }
      }
      this.countPlayTime();
    },
  },
  created() {
    this.$firebase.database().ref(this.monitor_vmd_publish_key).on('value', (sn) => {
      const publish = sn.val() || {};
      if (publish) {
        this.recentPublishTime = publish.lastPublishDateTime;
      }
    });

    this.$firebase.database().ref(this.monitor_vmd_root_key).once('value', (sn) => {
      let monitorvmd = sn.val() || [];

      let arr = [];
      for (const [hash, vmd] of Object.entries(monitorvmd)) {
        vmd.hash = hash;
        arr.push(vmd);
      }
      this.vmds = arr;

      this.reloadData();
      this.originalVmds = JSON.parse(JSON.stringify(this.vmds));
      if (this.vmds && this.vmds.length > 0) {
        this.clickedVmd = this.orderedVmd[0];
        this.activeIndex = 0;
        this.clickVmd(0, this.orderedVmd[0]);
      } else {
        this.clickAddBtn();
      }
      this.pageLoaded = true;
    })
  }
}
</script>

<style scoped>

</style>
